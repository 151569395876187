import "es6-promise/auto";
import "whatwg-fetch";
import "./RequestAnimationFrame";
import "./RequestIdleCallback";
import { DOM } from "lib/DOM";
import "./custom-elements-es5-adapter.js";
import "document-register-element";
import "url-polyfill";
import "es6-symbol/implement";
import "fastestsmallesttextencoderdecoder";
import "./toggleAttribute.js";

(async () => {
    if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
        await import( /* webpackChunkName: "internet-explorer" */ "./internetExplorerPolyfills");
    }
})();

window.setTimeout(async () => {
    await DOM.ready();

    const handleFirstTab = (e) => {
        if (e.keyCode === 9) { // the "I am a keyboard user" key
            document.body.classList.add("tabbing");
            window.removeEventListener("keydown", handleFirstTab);
        }
    }
    
    window.addEventListener("keydown", handleFirstTab);
});


